import { getVideoById, Video } from '../../utils/api/videoApi';
import { Color, colors } from '../../utils/styleguide';
import BigParagraphLayout from '../component-library/layout/BigParagraphLayout';
import { PageSectionComponent } from './pageSections';
import RichText from './RichText';
import sectionToCompatibleProps from './util/sectionToCompatibleProps';

type ServerSideProps = {
  portraitVideo?: Video;
  landscapeVideo?: Video;
  hideSection?: boolean;
};
const BigParagraphSection: PageSectionComponent<ServerSideProps> = ({
  section,
  serverSideProps: { portraitVideo, landscapeVideo, hideSection = false },
}) => {
  const { settings } = section.fields || {};
  const { id, title, description, backgroundColor, button, overline } = sectionToCompatibleProps({
    section,
  });

  // NOTE: Hot-fix for PPC. Remove when sustainability page is built
  if (hideSection) return null;

  const textColor =
    settings && 'textColor' in settings ? colors[settings.textColor as Color] : undefined;

  return (
    <BigParagraphLayout
      id={id}
      overline={overline}
      title={title && <RichText document={title} />}
      paragraph={<RichText document={description} textColor={textColor} />}
      href={button?.href}
      buttonLabel={button?.label}
      buttonColor={button?.colorContext}
      landscapeVideo={landscapeVideo}
      portraitVideo={portraitVideo}
      backgroundColor={backgroundColor}
    />
  );
};

BigParagraphSection.getCustomServerSideProps = async ({ context, section }) => {
  const { media } = sectionToCompatibleProps({ section });

  const ppcPartner = context?.params?.ppcPartner;
  const hasTermsAndConditions = ppcPartner?.includes('ihg');
  const hideSection = !!ppcPartner && !hasTermsAndConditions;

  const portraitVideo = getVideoById(media.videoIdPortrait);
  const landscapeVideo = getVideoById(media.videoIdLandscape);
  return { portraitVideo: await portraitVideo, landscapeVideo: await landscapeVideo, hideSection };
};

export default BigParagraphSection;
